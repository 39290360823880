/* eslint-disable */
import { toast } from 'react-toastify';
import { http } from '../../http';
import * as actionTypes from '../actionTypes';
import {GetWellnessInterestByUserId, AddUserLogWorkouts, GetUserLogWorkout} from '../../constants/apiConstants';
import {DeleteWorkout} from "../../constants/apiConstants";
const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});

const getWellnessInterestByUserId = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetWellnessInterestByUserId, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_USER_LOG_WORKOUT_CATEGORY, getLogWorkoutCategories : res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const addUserLogWorkouts = (logWorkouts) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(AddUserLogWorkouts, logWorkouts, config);
      if (res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        // window.location.replace('/portal');
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};

const getUserLogWorkout = (startDate, endDate) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      dispatch({type: actionTypes.USER_LOG_WORKOUT_LOADING_TRUE});
      const res = await http.get(`${GetUserLogWorkout}/${startDate}/${endDate}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_USER_LOG_WORKOUT, getLogWorkoutDetails : res});
        dispatch({type: actionTypes.USER_LOG_WORKOUT_LOADING_FALSE});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};



const deleteWork = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.delete(`${DeleteWorkout}/${id}`, config);
       window.location.reload();
      if(res.message) {
      
        toast.error(res.message);
      } else {
        // dispatch(updateList(id, updationList));
        toast.success(res[0]);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};


export {getWellnessInterestByUserId, addUserLogWorkouts, getUserLogWorkout,deleteWork};