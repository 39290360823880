import { toast } from "react-toastify";
import { http } from '../../http';
import * as actionTypes from "../actionTypes";
import { AddTeam, GetCompanyTeamUsers, GetCompanyTeamCount, GetUsersToAddToTeam, DeleteTeam, GetTeamDetailsById, FetchAddTeamMembers, AddOrUpdateTeamDetails, GetTeamIcons } from "../../constants/apiConstants";
import { FetchEmployeeCount } from '../companyDashboard';

const networkError = (error) => (dispatch) => dispatch({ type: actionTypes.NETWORK_ERROR, networkError: error });

const addChallengeTeams = (obj, id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(AddTeam,obj, config);
      if (res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.TEAM_CREATED, teamsCreatedStatus: res});
      }
      else {
        dispatch({type: actionTypes.TEAM_NOT_CREATED, teamsCreatedStatus: res[0]});
        dispatch(fetchCompanyTeamCount(id))
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const addChallengeTeamsV2 = (obj, id, pageNo, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(AddTeam,obj, config);
      if (res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.TEAM_CREATED, teamsCreatedStatus: res});
        dispatch(fetchCompanyTeamUsers(id, pageNo, limit));
      }
      else {
        dispatch({type: actionTypes.TEAM_NOT_CREATED, teamsCreatedStatus: res[0]});
        dispatch(fetchCompanyTeamCount(id));
        dispatch(fetchCompanyTeamUsers(id, pageNo, limit));
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchCompanyTeamUsers = (id, pageNo, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyTeamUsers}/${id}/${pageNo}/${limit}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_COMPANY_TEAM_MEMBERS, teamsUsersData: res});
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchCompanyTeamCount = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyTeamCount}/${id}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        // toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_COMPANY_TEAM_COUNT, teamsCount: res});
        dispatch(FetchEmployeeCount(id))
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchUsersToAddToTeam = (obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(GetUsersToAddToTeam, obj, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_USERS_ADD_TO_TEAM, teamUsers: res});
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchDeleteTeam = (teamId, id, pageNo, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.delete(`${DeleteTeam}/${teamId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(fetchCompanyTeamUsers(id, pageNo, limit));
        dispatch(fetchCompanyTeamCount(id))
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}
const fetchTeamIcons = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTeamIcons}`, {headers: { AUTHTOKEN: AuthToken }});
      if (res.message) {
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.TEAM_ICONS, teamIconsData: res});
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchTeamDetailsById = (teamId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTeamDetailsById}/${teamId}`, {headers: { AUTHTOKEN: AuthToken }});
      if (res.message) {
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.TEAM_DETAILS_BY_ID, teamDetailsData: res});
        dispatch(fetchTeamIcons());
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchAddTeamMembersData = (obj, id, pageNo, limit, teamId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(FetchAddTeamMembers, obj, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        toast.success(res[0]);
        dispatch(fetchCompanyTeamUsers(id, pageNo, limit));
        dispatch(fetchTeamDetailsById(teamId))
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchAddOrUpdateTeamDetails = (obj, id, pageNo, limit, teamId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(AddOrUpdateTeamDetails, obj, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        // toast.success(res[0]);
        dispatch(fetchCompanyTeamUsers(id, pageNo, limit));
        dispatch(fetchTeamDetailsById(teamId))
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

export { addChallengeTeams, fetchCompanyTeamUsers, fetchCompanyTeamCount, fetchUsersToAddToTeam, fetchDeleteTeam, fetchTeamDetailsById, fetchAddTeamMembersData, fetchAddOrUpdateTeamDetails, addChallengeTeamsV2 };

